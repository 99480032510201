/* src/app/app.component.scss */
body {
  padding-top: env(safe-area-inset-top);
}
ion-footer {
  padding-bottom: env(safe-area-inset-bottom);
}
.add_pc_bounds {
  position: absolute;
  top: 69px;
  left: 20px;
  width: 370px;
  height: 650px;
}
.add_pc_bounds_modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.iphonebg {
  background-image: url("./media/iphonebg.png");
  background-repeat: no-repeat;
  width: 415px;
  height: 815px;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -407.5px;
  margin-left: -207.5px;
}
.modal-wrapper {
  left: 0;
}
/*# sourceMappingURL=app.component.css.map */
