import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { from } from 'rxjs'
import { Storage } from '@ionic/storage-angular'


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private storage: Storage) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handle(request, next))
    }
    async handle(req: HttpRequest<any>, next: HttpHandler) {
      // if your getAuthToken() function declared as "async getAuthToken() {}"
      const token = await this.storage.get('access_token');
      const isApi = req.url.includes('/api')

      // if your getAuthToken() function declared to return an observable then you can use
      // await this.auth.getAuthToken().toPromise()


      if (token && isApi) {
        const authReq = req.clone({
          setHeaders: {
            'X-Auth-Token': token,
            'Content-Type': 'application/json'
          }
        })
        return next.handle(authReq).toPromise()
      } else {
        return next.handle(req).toPromise()
      }
    }

}
