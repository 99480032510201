import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ErrorHandler, NgModule, APP_INITIALIZER} from '@angular/core';
import OneSignal from 'onesignal-cordova-plugin';
import { Platform} from '@ionic/angular';
import {AppComponent} from './app.component';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SocialService } from './services/social.service';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FileSharer } from '@byteowls/capacitor-filesharer';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { environment } from '../environments/environment';
import {
    StorageService,
    BroadcastServiceProvider
} from './services/providers.service';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import {StoreModule} from "@ngrx/store";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApiService } from './services/api.service';
import { UserService, userDetails } from './services/user.service';
import { AppRoutingModule } from './app-routing.module';
import { TokenInterceptor } from './token.interceptor';


export function init(storageServiceProvide: StorageService, broadcastService: BroadcastServiceProvider) {
    return () => storageServiceProvide.load().then(a=>a).catch(e=>e);
}
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        IonicStorageModule.forRoot(),
        StoreModule.forRoot({ userDetails }),
        AppRoutingModule,
        IonicModule.forRoot({
            mode: 'ios'
        }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient, Platform]
            }
        })
    ],
    bootstrap: [AppComponent],
    providers: [{
            provide: APP_INITIALIZER,
            useFactory: init,
            deps: [StorageService, BroadcastServiceProvider],
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        SocialService,
        StorageService,
        ApiService,
        UserService,
        BroadcastServiceProvider,
        PhotoViewer
    ]
})
export class AppModule {
}
